/*jshint latedef: false */
/*************************************************************************************************/
/** JavaScript Bundle - Frontend *****************************************************************/
/*************************************************************************************************/

(function($) {

function randomString(len, charSet) {
  charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var randomString = '';
  for (var i = 0; i < len; i++) {
    var randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz,randomPoz+1);
  }
  return randomString;
}

/*************************************************************************************************/
/** Sage JavaScript Routing **********************************************************************/
/*************************************************************************************************/

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Sage = {
  // All pages
  'common': {
    init: function() {
      // JavaScript to be fired on all pages

      console.log("Frontend gulp JS loader: working");

      $('.footer-decor').detach().prependTo('.fusion-footer');
      $('.header-decor').detach().prependTo('.avada-page-titlebar-wrapper');

    },
    finalize: function() {
      // JavaScript to be fired on all pages, after page specific JS is fired
    }
  },
  // Home page
  'home': {
    init: function() {
      // JavaScript to be fired on the home page

      // Add decor triangles
      $('.slide-content').addClass('has-slideshow-decor').after('<div class="slideshow-decor"><div class="left-triangle"></div><div class="top-triangle"></div></div>');

    },
    finalize: function() {
      // JavaScript to be fired on the home page, after the init JS
    }
  },
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var fire;
    var namespace = Sage;
    funcname = (funcname === undefined) ? 'init' : funcname;
    fire = func !== '';
    fire = fire && namespace[func];
    fire = fire && typeof namespace[func][funcname] === 'function';

    if (fire) {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    // Fire common init JS
    UTIL.fire('common');

    // Fire page-specific init JS, and then finalize JS
    $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
      UTIL.fire(classnm);
      UTIL.fire(classnm, 'finalize');
    });

    // Fire common finalize JS
    UTIL.fire('common', 'finalize');
  }
};

// Load Events
$(document).ready(UTIL.loadEvents);

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

// Expose functions
//this.cc__load = cc__load;

})(jQuery); // Fully reference jQuery after this point.